<template>
  <div class="tab">
              <ul>
                  <li class='heading'>Academics</li>
                  
                  <li><router-link :to = "{name: 'Department'}">Department &amp; HOD</router-link></li>
                  <li><router-link :to = "{name: 'Counselling'}">Guidance &amp; Counselling</router-link></li>
                  <li><router-link :to = "{name: 'ClassTeachers'}">Class Teachers</router-link></li>
                  <li><router-link :to = "{name: 'Literary'}">Literary Activities</router-link></li>
                  <li><router-link :to = "{name: 'Toppers'}">Academic Toppers</router-link></li>
                  <li><router-link :to = "{name: 'Courses'}">Courses Offered</router-link></li>
                  <li><router-link :to = "{name: 'Publications'}">Publications</router-link></li>
              </ul>
          </div>
</template>

<script>
export default {

}
</script>

<style>

</style>